export const navItems = [
  //{ to: "Components",  label: "Components" },
  { to: "Components/Overview",  label: "Overview",  },
  { to: "Components/Typeface",  label: "Typeface", separator:"true", },
  { to: "Components/Colors",  label: "Color System",},
  { to: "Components/Icons",  label: "Icons" },
  { to: "Components/Buttons",  label: "Buttons" },
  { to: "Components/CheckboxSwitchers",  label: "Checkbox & Switchers" },
  { to: "Components/InputsAndForms",  label: "Input & Forms" },
  { to: "Components/CardsAndList",  label: "Cards & List",separator:"true", new:"true"  },
  { to: "Components/Layout",  label: "Layout & Switches" },
  { to: "Components/Navigation",  label: "Navigation & Tabs" },
  { to: "Components/TooltipAndPopover",  label: "Tooltip & Popover" },
  { to: "Components/Miscellaneous",  label: "Miscellaneous" },
  { to: "Components/Modal",  label: "Modal" },
  { to: "Components/Dashboard",  label: "Dashboard",separator:"true"  },
  { to: "Components/QuickDemos",  label: "Quick Demos",new:"true" },
];